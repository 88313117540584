'use strict';

angular.module('enervexSalesappApp').controller('StackRulePhaseCtrl', function($scope, StackRulePhase, $stateParams, Flash, $state, _) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged: false
	}
	if ($stateParams.id == "new") {
		$scope.item = {
			name: "",
			sortOrder: 0
		}
		fetch()
	} else {
		StackRulePhase.get({
			id: $stateParams.id
		}).$promise.then(function(item) {
			$scope.item = item;
			fetch()
		})
	}
	function fetch() {
		$scope.types = [
			"section",
			"top-level"
		]
		var computeAses = [
			{
				code: "COM"
			},{
				code: "BWH"
			}, {
				code: "DRY"
			}
		];
		$scope.computeAses = _.map(computeAses, function(computeAs) {
			var existing = _.find($scope.item.computeAses, function(o){
				return o == computeAs.code
			})
			if (existing){
				computeAs.selected = true;
			}
			return computeAs;
		})
	}

	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.item);
			payload = _.omit(payload, "__v");
			payload.computeAses = _.pluck(_.where($scope.computeAses, {selected:true}), "code")
			if (!$scope.item._id) {
				StackRulePhase.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully created StackRulePhase.', 0, {}, true);
					$state.go("stack-rule-phase", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				StackRulePhase.update({
					id: $scope.item._id
				}, payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully updated StackRulePhase.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});